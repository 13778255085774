import React from "react";
import useFooterData from "@staticQueries/FooterQuery";
import { Logo, FooterNav } from "@molecules";
import { Container, Button, Image } from "@atoms";
import { AppLink } from "@base";

const Footer = () => {
  const { privacyLink, copyright, nav, socials, button, awards } =
    useFooterData();
  return (
    <footer className="mt-auto bg-black py-10 text-white">
      <Container>
        <div className="flex flex-col gap-10 sm:flex-row sm:justify-between md:gap-20">
          {/* organization information */}
          <div className="flex max-w-[10rem] flex-shrink-0 flex-col gap-5">
            <AppLink to="/">
              <Logo />
            </AppLink>
            {button?.url && (
              <Button size="sm" to={button.url}>
                Talk to a specialist
              </Button>
            )}
            {awards?.length > 0 && (
              <div className="flex flex-wrap gap-3">
                {awards?.map(award => {
                  if (award?.url) {
                    return (
                      <AppLink
                        to={award.url}
                        ariaLabel={award?.ariaLabel}
                        className="w-full"
                      >
                        <Image
                          image={award?.image}
                          className="max-w-[6rem]"
                          draggable={false}
                          eager
                        />
                      </AppLink>
                    );
                  }
                  return (
                    <Image
                      image={award?.image}
                      className="max-w-[6rem]"
                      draggable={false}
                      eager
                    />
                  );
                })}
              </div>
            )}
          </div>
          {/* nav */}
          <div className="flex w-full md:justify-end">
            <FooterNav
              privacyLink={privacyLink}
              copyright={copyright}
              links={nav}
              socials={socials}
            />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
