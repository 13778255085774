import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query FooterQuery {
    craft {
      globalSet(handle: "footer") {
        ... on Craft_footer_GlobalSet {
          # todo: add social
          copyright: descriptor0
          privacyLink: link0 {
            url
            text
          }
          button: link1 {
            url
            text
          }
          # award
          awards {
            ... on Craft_awards_award_BlockType {
              image {
                ...ImageFragment
              }
              linkObject {
                url
                ariaLabel
              }
            }
          }
          # nav
          linkList {
            ... on Craft_linkList_link_BlockType {
              uid
              linkObject {
                url
                text
              }
              sublinks: footerSublinks {
                ... on Craft_footerSublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
          social {
            ... on Craft_social_account_BlockType {
              uid
              platform
              url: accounturl
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const { linkList, copyright, privacyLink, social, button, awards } =
    globalSet;

  const nav = linkList.map(link => {
    return {
      uid: link.uid,
      text: link.linkObject.text,
      url: link.linkObject.url,
      sublinks: link.sublinks.map(l => ({
        uid: l.uid,
        ...l.sublink,
      })),
    };
  });

  return {
    nav,
    copyright,
    privacyLink,
    socials: social,
    button,
    awards: awards?.map(award => ({
      image: resolveImage(award?.image),
      url: award?.linkObject?.url,
      ariaLabel: award?.linkObject?.ariaLabel,
    })),
  };
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
